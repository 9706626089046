<template>
<div>
	<TopNav></TopNav>
	<Header></Header>
	
	<div class="FixedSearch" v-if="FixedSearchShow">
		<div class="Wrap">
			<div class="Search">
				<div class="Logo">
					<img src="../../assets/img/common/logo.jpg" />
				</div>
				<div class="Input">
					<input v-model="FixSearchKeywords"  @keyup.enter.native="FixSearch" type="text" />
				</div>
				<div class="Btn" @click="FixSearch()">搜索</div>
				<div class="All" @click="$Jump('/goods/goods_list')">全部商品</div>
			</div>
		</div>
	</div>
	
	<div class="A1" :style="'background-color:'+SwiperBgColor">
		<div class="Swip">
			
			<!--分类导读-->
			<div class="Cates">
				<template v-for="(father,fatherI) in AllCates">
				<li :key="father.Id" v-if="fatherI < 15" :class="'l'+(fatherI+1)">
					<a :href="'/#/goods/goods_list?c1='+father.Id" target="_blank">
						<i :class="father.Icon" v-if="father.Icon != ''"></i>
						<i class="el-icon-more-outline" v-else></i>
						{{father.Name}}
					</a>
				</li>
				</template>
				<li>
					<a href="/#/goods/goods_list" target="_blank"><i class="el-icon-more-outline"></i>全部商品</a>
				</li>
			</div>
			
			<div class="Swiper">
				
				<el-carousel height="480px" :arrow="'never'" @change="siwperChange">
				  <el-carousel-item v-for="item in SwiperList" :key="item.Id">
					<a style="display: block; width: 100%; height: 100%" target="_blank" :href="'/#'+item.JumpTarget">
						<el-image
						      style="width: 100%; height: 100%"
						      :src="item.ShowThumb">
						</el-image>
					</a>
				  </el-carousel-item>
				</el-carousel>
				
			</div>

			<div class="Suggest">
				<ul>
					<a target="_blank" :href="'/#'+sug.JumpTarget" v-for="(sug,sugI) in SwiperRightFloat" :key="sugI">
						<img :src="sug.ShowThumb" />
					</a>
				</ul>
			</div>
			
		</div>
	</div>

	
	<!--品牌活动-->
	<div class="Wrap">
	<div class="Brand">
		
		<div class="Title">
			 <h3>品牌名店</h3>
			 <span>BRAND & SHOP</span>
		</div>
		
		<!--3推荐活动 品牌/店铺-->
		<div class="Recommend">
			
			<a class="Activity" target="_blank" :href="'/#'+ad.JumpTarget" v-for="ad in Activity3" :key="ad.Id">
				<div class="Image">
					<el-image fit="cover" style="width: 100%;height: 100%;" :src="ad.ShowThumb"></el-image>
				</div>
			</a>
			
		</div>
	
		
	</div>
	</div>
	 
	 <!--家居日用分类商品推荐-->
	 <div class="Wrap">
	 <div class="CateGoods">
	 		 <div class="Title">
	 			 <h3>爆款推荐</h3>
	 			 <span>HOT</span>
	 		 </div>
	 		 <div class="Recommend">
	 			 <a class="CateBanner" href="/#/goods/goods_list?c1=033f26cb-27b7-4ba7-80d6-61c34c6cf489" target="_blank">
	 				 <div class="Image">
	 					 <el-image fit="cover" style="width: 100%;height: 360px;" src="https://img.wojiasx.com/s/00fb0397-487b-4c5a-a800-cacd8f6df7e0.jpeg"></el-image>
	 				 </div>
	 				 <div class="CateDesc">
	 					 <div class="Desc1">爆款推荐</div>
	 					 <div class="Desc2">
	 						 自用礼赠爆款经典
	 					 </div>
	 				 </div>
	 				 
	 				 <div class="Cover"></div>
	 			 </a>
	 			 <div class="GoodsList">
	 			 	<a class="Goods" :href="'/#/goods/detail/'+goods.Id" target="_blank" v-for="goods in Grocery10Star" :key="goods.Id">
	 			 		<div class="Thumb">
	 			 			<img :src="goods.Thumb" />
	 			 		</div>
	 			 		<div class="Name">
	 			 			{{goods.Title}}
	 			 		</div>
	 			 		<div class="Price">
	 			 			￥{{goods.Price}}
	 			 		</div>
	 			 	</a>
	 			 </div>
	 		 </div>
	 </div>
	 </div>
	 
	 
	 
	<div class="Wrap" v-if="Guess5Star.length > 0">
		<div class="Guess">
			<div class="Title">
				<span class="el-icon-minus"></span><span class="el-icon-minus"></span><i class="el-icon-star-off"></i><h3>猜你喜欢</h3><span class="el-icon-minus"></span><span class="el-icon-minus"></span>
			</div>
			<ul class="GoodsList"  style="overflow:auto">
				<a class="Goods" :href="'/#/goods/detail/'+goods.Id" target="_blank" v-for="goods in Guess5Star" :key="goods.Id">
					<div class="Thumb">
						<img :src="goods.Thumb" />
					</div>
					<div class="Name">
						{{goods.Title}}
					</div>
					<div class="Price">
						￥{{goods.Price}}
					</div>
				</a>
			</ul>
			<div class="More" v-if="Guess5Star.length <= 20">
				<span @click="GetGuessNext()"><i class="el-icon-arrow-down"></i>点击加载更多...</span>
			</div>
		</div>
	</div>

	<Footer></Footer>
</div>
</template>

<script>
	import Vue from 'vue'
	import TopNav from '@/components/Top.vue'
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	import {  Carousel,CarouselItem,Message,Image } from 'element-ui';
	export default {
	  name: 'Index',
	  props: {
	    msg: String
	  },
	  data() {
	      return {
			FixedSearchShow:false,
			FixSearchKeywords:'',
	        GoodsRecommend: [],
			NewsList:[],
			BannerThree:[],//首页轮播图下方3广告图
			AllCates:[
    {
        "Id": "debca459-99bb-48b5-a741-e47fe9198ac3",
        "Name": "蔬菜",
        "ShortName": "蔬菜",
        "Icon": "el-icon-watermelon",
        "FatherId": "",
        "Level": 1,
        "Order": 0,
        "Children": null
    },
    {
        "Id": "05200935-b896-4da2-a767-0577a3f1f669",
        "Name": "水果",
        "ShortName": "水果",
        "Icon": "el-icon-apple",
        "FatherId": "",
        "Level": 1,
        "Order": 0,
        "Children": null
    },
    {
        "Id": "35becea5-8a6a-4113-9409-a2b4b81f3492",
        "Name": "粮食",
        "ShortName": "粮食",
        "Icon": "el-icon-hot-water",
        "FatherId": "",
        "Level": 1,
        "Order": 0,
        "Children": null
    },
    {
        "Id": "821d55c4-78b7-4667-bbdf-67ffd0329707",
        "Name": "禽肉",
        "ShortName": "禽肉",
        "Icon": "el-icon-chicken",
        "FatherId": "",
        "Level": 1,
        "Order": 0,
        "Children": null
    },
    {
        "Id": "461cdd73-3c3b-41da-9d07-70446a09e7ec",
        "Name": "蛋类",
        "ShortName": "蛋",
        "Icon": "el-icon-burger",
        "FatherId": "",
        "Level": 1,
        "Order": 0,
        "Children": null
    },
    {
        "Id": "db8bcf3e-3b7f-41c5-b99e-b4acf4d9bdbf",
        "Name": "土特产",
        "ShortName": "土特产",
        "Icon": "el-icon-ice-cream",
        "FatherId": "",
        "Level": 1,
        "Order": 0,
        "Children": null
    },
    {
        "Id": "3ff37ab8-37ff-466e-b96c-1f0fcee50559",
        "Name": "海鲜水产",
        "ShortName": "海鲜水产",
        "Icon": "el-icon-moon-night",
        "FatherId": "",
        "Level": 1,
        "Order": 0,
        "Children": null
    },
    {
        "Id": "2d1df430-7fdb-4583-82f4-547734fde75a",
        "Name": "绿色农产品",
        "ShortName": "绿色农产品",
        "Icon": "el-icon-cloudy-and-sunny",
        "FatherId": "",
        "Level": 1,
        "Order": 0,
        "Children": null
    },
    {
        "Id": "e7712f08-6187-4b90-ba1a-d7f68dc2c010",
        "Name": "休闲农业",
        "ShortName": "休闲农业",
        "Icon": "el-icon-cherry",
        "FatherId": "",
        "Level": 1,
        "Order": 0,
        "Children": null
    }
],//全部商品分类
			SwiperList:[
			  {
				BgColor:'rgba(255,255 255, 1)',
				Id: "80c47a3b-a2e5-47d8-9bba-605b6b18e51b",
				JumpTarget: "/goods/goods_list?c1=debca459-99bb-48b5-a741-e47fe9198ac3",
				JumpType: "uri",
				ShowThumb:"https://img.wojiasx.com/s/73c6442c-87ea-4f0f-a147-772dc995d061.jpeg"
			  },
			  {
				BgColor: 'rgba(153, 134, 127, 1)',
				Id: "80c47a3b-a2e5-47d8-9bba-605b6b18e51c",
				JumpTarget: "/goods/goods_list?keywords=米",
				JumpType: "uri",
				ShowThumb:"https://img.wojiasx.com/s/bebc6498-4a3b-49f8-8432-77fb4ec82512.jpeg"
				}
			],//首页轮播广告
			SwiperBgColor:'rgba(255,255 255, 1)',
			Activity3:[
				{
					Id:'23580187-c2be-4213-8437-e2568b85d164',
					JumpTarget:'/coupon/index',
					ShowThumb:'https://img.wojiasx.com/s/b56ffe84-ed85-4ff4-85c3-48b60eb437b8.jpeg',
					JumpType:'uri',
				},
				{
					Id:'a2fbe81b-cb7b-458a-8c4a-0e9b83f1db30',
					ShowThumb:'https://img.wojiasx.com/s/6d97640c-36a2-4776-8553-a9d8afbe40d2.jpeg',
					JumpType:'uri',
					JumpTarget:'/goods/goods_list?keywords=榴莲'
				},
				{
					Id:'6a0d2d9b-f900-43b8-9e13-07c5b688621b',
					ShowThumb:'https://img.wojiasx.com/s/d259d48d-e646-4bc0-8b9b-4d80defbdf15.jpeg',
					JumpType:'uri',
					JumpTarget:'/goods/goods_list?keywords=烧烤'
				}
			],
			Grocery10Star:[],
			Outdoors10Star:[],
			Office10Star:[],
			Guess5Star:[],
			SwiperRightFloat:[
				{
					Id:'12313',
					ShowTitle:'',
					ShowThumb:'https://img.wojiasx.com/s/436cfd45-6e69-4577-bf22-44555f31b0ca.jpeg',
					JumpType:'uri',
					JumpTarget:''
				},
				{
					Id:'12314',
					ShowTitle:'',
					ShowThumb:'https://img.wojiasx.com/s/a3f6a6fb-b92f-4bca-bdc1-7356ab46369b.jpeg',
					JumpType:'uri',
					JumpTarget:''
				}
			],
	      }
	    },
		mounted () {
			window.addEventListener('scroll', this.handleScroll)
		},
		destroyed () {
			window.removeEventListener('scroll', this.handleScroll)
		},
	  components: {
	  	  TopNav,
		  Header,
		  Footer,
		  "el-carousel":Carousel,
		  "el-carousel-item":CarouselItem,
		  "el-image":Image
	  },
	  created() {
	 //  	this.Recommend(1)
		// this.GetCategoryList(1)
		this.GetAllCates()
		this.GetSwiper()
		this.GetSwiperRight()
		this.GetActivityThree()
		this.GetGoodsRecommend()
		this.GetBrand10Star()
		this.GetGrocery10Star()
		this.GetGuessNext()
	  },
	  methods:{
		  FixSearch(){
			  this.$store.dispatch('setTopKeywords',this.FixSearchKeywords)
			  this.$Jump('/goods/goods_list?keywords='+this.FixSearchKeywords)
		  },
		  handleScroll () {
			var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			if(scrollTop >= 260){
				this.FixedSearchShow = true
			}else{
				this.FixedSearchShow = false
			}
		  },
		  siwperChange(_index){
			  this.SwiperBgColor = this.SwiperList[_index].BgColor
		  },
		  GetAllCates(){ 
		  		let data = {
		  			Service:'Goods',
		  			Class: 'GoodsCategory',
		  			Action: 'GetCategoryTree',
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
		  					if(res.Data.CategoryList == undefined || res.Data.CategoryList == null || res.Data.CategoryList.length == 0){
		  						return
		  					}
		  			this.AllCates = res.Data.CategoryList
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  GetSwiper(){ //获取静态广告图
		  		let data = {
		  			Service:'Ad',
		  			Class: 'Ad',
		  			Action: 'List',
		  			Page:1,
		  			PageSize:3,
					SKey:'pc_index_swiper'
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
					if(res.Data.AdList == undefined || res.Data.AdList == null || res.Data.AdList.length == 0){
						return
					}
		  			this.SwiperList = res.Data.AdList
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  GetSwiperRight(){ //获取静态广告图
		  		let data = {
		  			Service:'Ad',
		  			Class: 'Ad',
		  			Action: 'List',
		  			Page:1,
		  			PageSize:2,
		  			SKey:'pc_home_swiper_static'
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
		  					if(res.Data.AdList == undefined || res.Data.AdList == null || res.Data.AdList.length == 0){
		  						return
		  					}
		  			this.SwiperRightFloat = res.Data.AdList
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  GetActivityThree(){ //
		  		let data = {
		  			Service:'Ad',
		  			Class: 'Ad',
		  			Action: 'List',
		  			Page:1,
		  			PageSize:3,
		  			SKey:'index_min_banner'
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
		  			this.Activity3 = res.Data.AdList
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  GetSwiperList(){ //获取轮播广告图
		  		let data = {
		  			Service:'Ad',
		  			Class: 'Ad',
		  			Action: 'List',
		  			Page:1,
		  			PageSize:5,
		  			SKey:'pc_index_swiper'
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
		  			this.SwiperList = res.Data.AdList
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  GetBrand10Star(){
		  			  let data = {
		  			  	Service:'Goods',
		  			  	Class: 'Brand',
		  			  	Action: 'List',
		  			  	Page:1,
		  			  	PageSize:30,
		  				Star:10,
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	
		  			  	if(res.ErrorId != 0){
		  			  		this.$message(res.Msg)
		  			  		return
		  			  	}
						if(res.Data.BrandList == undefined || res.Data.BrandList == null || res.Data.BrandList.length == 0){
							return
						}
		  			  	this.Brand10Star = res.Data.BrandList
		  			  })
		  			  .catch(function (response) {
		  			  	this.$message('网络请求错误')
		  			  })
		  },
		  GetGrocery10Star(){
		  			  let data = {
		  			  	Service:'Goods',
		  			  	Class: 'Goods',
		  			  	Action: 'List',
		  			  	Page:1,
		  			  	PageSize:4,
						Star:10,
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	
		  			  	if(res.ErrorId != 0){
		  			  		this.$message(res.Msg)
		  			  		return
		  			  	}
						if(res.Data.GoodsList == undefined || res.Data.GoodsList == null || res.Data.GoodsList.length == 0){
							return
						}
		  			  	this.Grocery10Star = res.Data.GoodsList
		  			  })
		  			  .catch(function (response) {
		  			  	this.$message('网络请求错误')
		  			  })
		  },
		 
		  GetGuessNext(){
			  if(this.Guess5Star.length == 0){
			  	 this.GetGuess5Star(1)
			  }else if(this.Guess5Star.length > 0 && this.Guess5Star.length <= 20){
				  this.GetGuess5Star(2)
			  }else if(this.Guess5Star.length >= 20 && this.Guess5Star.length <= 40){
				  this.GetGuess5Star(3)
			  }
		  },
		  GetGuess5Star(_page){
		  			  let data = {
		  			  	Service:'Goods',
		  			  	Class: 'Goods',
		  			  	Action: 'List',
		  			  	Page:_page,
		  			  	PageSize:20,
		  				Star:5,
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	
		  			  	if(res.ErrorId != 0){
		  			  		this.$message(res.Msg)
		  			  		return
		  			  	}
		  						if(res.Data.GoodsList == undefined || res.Data.GoodsList == null || res.Data.GoodsList.length == 0){
		  							return
		  						}
							this.Guess5Star = this.Guess5Star.concat(res.Data.GoodsList)
		  			  })
		  			  .catch(function (response) {
		  			  	this.$message('网络请求错误')
		  			  })
		  },
		  GetGoodsRecommend(){
			  let data = {
			  	Service:'Goods',
			  	Class: 'Goods',
			  	Action: 'List',
			  	Page:1,
			  	PageSize:30,
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  	
			  	if(res.ErrorId != 0){
			  		this.$message(res.Msg)
			  		return
			  	}
			  	this.GoodsRecommend = res.Data.GoodsList
			  })
			  .catch(function (response) {
			  	this.$message('网络请求错误')
			  })
		  }
		  
	  }
	}
</script>

<style scoped>

.A1{
	display: flex;
	justify-content: center;
}
.Swip{
	position: relative;
	width: 1280px;
	height: 480px;
}
.Swip .Cates{
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 10;
	height: 480px;
	width: 200px;
	background-color: rgba(0,0,0,0.4);
}
.Swip .Cates li {
	height: 30px;
	line-height: 30px;
	color: rgba(255,255,255,0.9);
	position: relative;
}
.Swip .Cates li a{
	display: block;
	width: 180px;
	height:30px;
	cursor: pointer;
	padding-left: 20px;
	color: #FFFFFF;
}
.Swip .Cates li a i{
	margin-right: 5px;
}
.Swip .Cates li:hover a{
	background-color: #ffffff;
	color: rgba(25,135,66,1);
}
.Swip .Cates li ul{
	display: none;
	position: absolute;
	left: 200px;
	top: 0px;
	width: 860px;
	height: 480px;
	overflow: hidden;
	background-color: #ffffff;
}
.Swip .Cates li ul a{
	display: block;
	font-size: 1.1rem;
	padding: 0px;
	margin: 0px;
	margin-top: 5px;
	margin-left: 10px;
	color: #000000;
}
.Swip .Cates li ul div{
	word-wrap: break-word;
	margin-left: 10px;
	margin-right: 10px;
}
.Swip .Cates div .Act{
	font-size: 1.0rem;
	padding: 0px;
	margin: 0px;
	margin-right: 5px;
	margin-top: 3px;
	display: inline;
	color: rgba(0,0,0,0.7);
}
.Swip .Cates li.l2 ul{
	top: -30px;
}
.Swip .Cates li.l3 ul{
	top: -60px;
}
.Swip .Cates li.l4 ul{
	top: -90px;
}
.Swip .Cates li.l5 ul{
	top: -120px;
}
.Swip .Cates li.l6 ul{
	top: -150px;
}
.Swip .Cates li.l7 ul{
	top: -180px;
}
.Swip .Cates li.l8 ul{
	top: -210px;
}
.Swip .Cates li.l9 ul{
	top: -240px;
}
.Swip .Cates li.l10 ul{
	top: -270px;
}
.Swip .Cates li.l11 ul{
	top: -300px;
}
.Swip .Cates li.l12 ul{
	top: -330px;
}
.Swip .Cates li.l13 ul{
	top: -360px;
}
.Swip .Cates li.l14 ul{
	top: -390px;
}
.Swip .Cates li.l15 ul{
	top: -420px;
}
.Swip .Cates li.l16 ul{
	top: -450px;
}

.Swip .Cates li:hover ul{
	display: block;
}
.Swip .Swiper{
	height: 480px;
	width: 1280px;
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 2;
}
.Swip .Suggest{
	position: absolute;
	right: 0px;
	top: 0px;
	z-index: 10;
	height: 480px;
	width: 180px;
}
.Swip .Suggest ul{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	width: 180px;
	height: 380px;
	margin-top: 50px;
}
.Swip .Suggest ul a{
	background-color: rgba(255,255,255,0.45);
	padding: 20px;
	width: 140px;
	height: 140px;
	display: block;
}
.Swip .Suggest ul a:hover{
	background-color: rgba(255,255,255,0.7);
}
.Swip .Suggest ul a img{
		width: 100%;
		height: 100%;
}

.Brand{
	margin-top: 20px;
	width: 100%;
}

.Brand .Title {
	display: flex;
	width: 100%;
	color: rgba(51,51,51,1);
}
.Brand .Title h3{
	border-left: 5px solid rgba(51,51,51,1);
	padding: 0px;
	margin: 0px;
	height: 20px;
	line-height: 20px;
	padding-left: 10px;
	margin-right: 10px;
}

.Brand .Recommend{
	width: 100%;
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
}
.Brand .Recommend .Activity{
	width: 420px;
	height: 200px;
	position: relative;
	cursor: pointer;
}
.Brand .Recommend .Activity .Image{
	width: 100%;
	height: 200px;
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 0;
	opacity:1;
	transition: opacity 1.2s;
	-moz-transition: opacity 1.2s;	/* Firefox 4 */
	-webkit-transition: opacity 1.2s;	/* Safari 和 Chrome */
	-o-transition: opacity 1.2s;	/* Opera */
}
.Brand .Recommend .Activity:hover .Image{
	opacity:0.7;
}
.Brand .Recommend .Activity .Name{
	width: 200px;
	height: 30px;
	background-color: rgba(0,0,0,0.6);
	color: rgba(255,255,255,0.7);
	border-radius: 0px 15px 15px 0px;
	padding-left: 20px;
	line-height: 30px;
	position: absolute;
	left: 0px;
	top: 15px;
	z-index: 2;
}
.Brand .Recommend .Activity .Tips{
	width: 200px;
	height: 20px;
	padding-left: 20px;
	line-height: 20px;
	position: absolute;
	left: 0px;
	top: 45px;
	z-index: 2;
	color:rgba(255,255,255,0.6);
}
.Brand  .Shopes{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	background-color: #FFFFFF;
	margin-top: 15px;
} 
.Brand  .Shopes .Shop{
	width: 127px;
	border-right: 1px solid rgba(0,0,0,0.04);
	border-bottom: 1px solid rgba(0,0,0,0.04);
	height: 100px;
	display: block;
	position: relative;
	cursor: pointer;
}
.Brand  .Shopes .Shop .Thumb{
	position: absolute;
	width: 80px;
	height: 50px;
	left: 23px;
	top: 25px;
	z-index: 0;
	display: flex;
	align-items: center;
}
.Brand  .Shopes .Shop .Info{
	position: absolute;
	width: 100%;
	height: 50px;
	padding: 25px 0px;
	left: 0px;
	top: 0px;
	z-index: 1;
	background-color: rgba(0,0,0,0.7);
	opacity: 0;
	transition: opacity 1.2s;
	-moz-transition: opacity 1.2s;	/* Firefox 4 */
	-webkit-transition: opacity 1.2s;	/* Safari 和 Chrome */
	-o-transition: opacity 1.2s;	/* Opera */
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}
.Brand  .Shopes .Shop:hover .Info{
	opacity: 1;
}
.Brand  .Shopes .Shop .Info span{
	display: block;
	color: #FFFFFF;
	text-align: center;
	width: 100%;
}
.Brand  .Shopes .Shop .Info em{
	display: block;
	color: #FFFFFF;
	text-align: center;
	height: 26px;
	line-height: 26px;
	border-radius: 13px;
	padding: 0px 10px;
	background-color: rgba(23,134,65,1);
}

.CateGoods{
	margin-top: 20px;
	width: 100%;
}
.CateGoods .Title {
	display: flex;
	width: 100%;
	color: rgba(51,51,51,1);
}
.CateGoods .Title h3{
	border-left: 5px solid rgba(51,51,51,1);
	padding: 0px;
	margin: 0px;
	height: 20px;
	line-height: 20px;
	padding-left: 10px;
	margin-right: 10px;
}
.CateGoods .Recommend{
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
}
.CateGoods .Recommend .CateBanner{
	width: 240px;
	position: relative;
	cursor: pointer;
}
.CateGoods .Recommend .CateBanner .Image{
	width: 240px;
	height: 100%;
	opacity:1;
	transition: opacity 1.2s;
	-moz-transition: opacity 1.2s;	/* Firefox 4 */
	-webkit-transition: opacity 1.2s;	/* Safari 和 Chrome */
	-o-transition: opacity 1.2s;	/* Opera */
}
.CateGoods .Recommend .CateBanner:hover .Image{
	opacity: 0.7;
}
.CateGoods .Recommend .CateBanner .CateDesc{
	width: 200px;
	height: 64px;
	position: absolute;
	left: 0px;
	bottom: 150px;
	z-index: 2;
	background-color: rgba(0,0,0,0.6);
	display: flex;
	font-weight: lighter;
	align-items: center;
}
.CateGoods .Recommend .CateBanner .CateDesc .Desc1{
	display: flex;
	line-height: 22px;
	font-size: 18px;
	width: 36px;
	height: 44px;
	padding: 10px 20px;
	color: #ffffff;
	background-color: rgba(0,0,0,0.5);
	align-items: center;
	justify-content: center;
}
.CateGoods .Recommend .CateBanner .CateDesc .Desc2{
	display: flex;
	line-height: 22px;
	font-size: 18px;
	width: 72px;
	height: 44px;
	padding: 10px 20px 10px 10px;
	color: rgba(255,255,255,0.7);
	align-items: center;
	justify-content: center;
}

.CateGoods .Recommend .CateBanner .Go{
	width: 40px;
	height: 40px;
	position: absolute;
	right: 20px;
	bottom: 27.5px;
	z-index: 2;
	border: 2px solid rgba(255,255,255,0.8);
	color: rgba(255,255,255,0.8);
	line-height: 40px;
	font-size: 1.2rem;
	font-weight: bold;
	text-align: center;
	border-radius: 21px;
}
.CateGoods .Recommend .CateBanner .Cover{
	width: 100%;
	height: 7.5px;
	background-color: rgba(242,242,242,1);
	position: absolute;
	right: 0px;
	bottom: 0px;
	z-index: 2;
}


.CateGoods .Recommend .GoodsList{
	flex: 1;
	margin-left: 15.5px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.CateGoods .Recommend .GoodsList .Goods{
	width: 248px;
	border: 1px solid #FFFFFF;
	background-color: #FFFFFF;
	padding-bottom: 20px;
	line-height: 20px;
	cursor: pointer;
	margin-bottom: 7.5px;
	opacity:1;
	transition: opacity 1.2s;
	-moz-transition: opacity 1.2s;	/* Firefox 4 */
	-webkit-transition: opacity 1.2s;	/* Safari 和 Chrome */
	-o-transition: opacity 1.2s;	/* Opera */
}
.CateGoods .Recommend .GoodsList .Goods:hover{
	border: 1px solid rgba(23,134,65,1);
	opacity: 0.7;
}
.CateGoods .Recommend .GoodsList .Goods .Thumb{
	width: 248px;
	height: 248px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.CateGoods .Recommend .GoodsList .Goods .Thumb img{
	width: 200px;
	height: 200px;
}
.CateGoods .Recommend .GoodsList .Goods .Name{
	height: 40px;
	padding: 0px 30px;
	line-height: 20px;
	color: rgba(0,0,0,0.7);
	text-align: center;

	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-break: break-all;
}
.CateGoods .Recommend .GoodsList .Goods .Price{
	font-size: 1.2rem;
	line-height: 20px;
	height: 20px;
	color: rgba(23,134,65,1);
	text-align: center;
	margin-top: 8px;
}


.Guess{
	width: 100%;
}
.Guess .Title{
	width: 100%;
	display: flex;
	padding: 20px 0px;
	justify-content: center;
	align-items: center;
	color: rgba(0,0,0,0.5);
}
.Guess .Title h3{
	margin: 0px;
	padding: 0px 5px;
	font-weight: normal;
}
.Guess .Title i{
	color: rgba(25,135,66,1);
}

.Guess .GoodsList{
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.Guess .GoodsList .Goods{
	width: 248px;
	border: 1px solid #FFFFFF;
	background-color: #FFFFFF;
	padding-bottom: 20px;
	line-height: 20px;
	cursor: pointer;
	margin-bottom: 7.5px;
	opacity:1;
	transition: opacity 1.2s;
	-moz-transition: opacity 1.2s;	/* Firefox 4 */
	-webkit-transition: opacity 1.2s;	/* Safari 和 Chrome */
	-o-transition: opacity 1.2s;	/* Opera */
}
.Guess .GoodsList .Goods:hover{
	border: 1px solid rgba(23,134,65,1);
	opacity: 0.7;
}
.Guess .GoodsList .Goods .Thumb{
	width: 248px;
	height: 248px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.Guess .GoodsList .Goods .Thumb img{
	width: 200px;
	height: 200px;
}
.Guess .GoodsList .Goods .Name{
	height: 40px;
	padding: 0px 30px;
	line-height: 20px;
	color: rgba(0,0,0,0.5);
	text-align: center;
	
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-break: break-all;
}
.Guess .GoodsList .Goods .Price{
	font-size: 1.2rem;
	color: rgba(25,135,66,1);
	text-align: center;
	margin-top: 15px;
}
.Guess .More{
	padding: 20px;
	text-align: center;
}
.Guess .More span{
	color: rgba(0,0,0,0.4);
	display: inline-block;
	border: 1px solid rgba(0,0,0,0.07);
	padding: 0px 80px;
	height: 30px;
	line-height:30px;
	border-radius: 16px;
	cursor: pointer;
}
.Guess .More span i{
	margin-right: 10px;
}
.Guess .More span:hover{
	background-color: rgba(25,135,66,1);
	color: #FFFFFF;
}

.FixedSearch{
	width: 100%;
	height: 50px;
	position: fixed;
	left: 0px;
	top: 0px;
	z-index: 99999;
	background-color: rgba(25,135,66,1);
}
.FixedSearch .Search{
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(255,255,255,0.2);
}
.FixedSearch .Search .Logo{
	height: 35px;
	margin-right:120px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: #ffffff;
}
.FixedSearch .Search .Logo img{
	height: 35px;
}
.FixedSearch .Search .Input{
	
}
.FixedSearch .Search .Input input{
	padding: 0px;
	margin: 0px;
	border: 0px solid #409EFF;
	height: 30px;
	line-height: 30px;
	padding: 0px 10px;
	color: rgba(0,0,0,0.6);
	width: 300px;
}
.FixedSearch .Search .Btn{
	display: block;
	width: 100px;
	text-align: center;
	height: 28px;
	line-height: 28px;
	cursor: pointer;
	border: 1px solid #FFFFFF;
	color: #FFFFFF;
}
.FixedSearch .Search .All{
	flex: 1;
	text-align: right;
	color: #FFFFFF;
	cursor: pointer;
}
</style>
